(function () {
  'use strict';

  /* @ngdoc object
   * @name noConnection
   * @description
   *
   */
  angular
    .module('noConnection', [
      'ui.router'
    ]);
}());
